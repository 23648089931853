import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import React, { forwardRef, useRef } from 'react';
import {
  DiaryInstance,
  MEAL_TIME_IMAGES,
  MEAL_TIME_NAMES,
  MealTimeInstance,
  MealTimeNamesInstance,
  NO_IMAGE_PATH,
  NutritionInstance,
  ProductInstance,
} from 'stores';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { Nutrition } from 'src/components/recipies';
import { getSnapshot } from 'mobx-state-tree';
import { useBoolean } from 'hooks';
import { formatGramms } from 'utils';
import { useTranslate } from 'i18n';

export const MealTimeGroupListItem: React.FC<
  {
    name: MealTimeNamesInstance;
    nutrition: NutritionInstance;
  } & ListItemProps
> = ({ name, nutrition, ...props }) => {
  const translate = useTranslate();
  return (
    <ListItem {...props}>
      <ListItemAvatar>
        <Box
          component="img"
          src={MEAL_TIME_IMAGES[name]}
          sx={{
            aspectRatio: '1/1',
            height: 'auto',
            width: '3rem',
            objectFit: 'contain',
          }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={translate(MEAL_TIME_NAMES[name])}
        secondaryTypographyProps={{ component: 'div' }}
        secondary={
          <Nutrition nutrition={nutrition} variant="outlined" size="small" />
        }
      />
    </ListItem>
  );
};

const IntValue = ({
  count,
  quantity,
  unitName,
}: {
  count: number;
  quantity: number;
  unitName?: string;
}) => {
  const translate = useTranslate();
  return (
    <>
      {formatGramms(quantity * count, unitName)} {translate('из')}{' '}
      {formatGramms(quantity, unitName)}
    </>
  );
};

interface FractionValueProps {
  intVal: number;
  fractionCount: number;
  count_fractions_in_product?: number | null;
  quantity: number;
  unitName?: string;
}

const FractionValue = ({
  intVal,
  fractionCount,
  count_fractions_in_product,
  quantity,
  unitName,
}: FractionValueProps) => {
  const translate = useTranslate();
  return (
    <>
      {intVal || ''}
      <sup>{fractionCount}</sup>&frasl;
      <sub>{count_fractions_in_product}</sub> {translate('от')}{' '}
      {formatGramms(quantity, unitName)}
    </>
  );
};

interface FullValueProps {
  count: number;
  quantity: number;
  unitName?: string;
}

const FullOrFractionValue: React.FC<FractionValueProps | FullValueProps> = (
  props,
) => {
  if (
    'count_fractions_in_product' in props &&
    !!props.count_fractions_in_product
  ) {
    if (props.fractionCount > 0) {
      return <FractionValue {...props} />;
    }
    return <IntValue {...props} count={props.intVal} />;
  }
  if ('count' in props) {
    return <IntValue {...props} />;
  }
  return null;
};

export const MealTimeListItem = forwardRef<
  HTMLLIElement,
  {
    mealTime: MealTimeInstance;
  } & ListItemProps
>(({ mealTime, ...props }, ref) => (
  <ListItem ref={ref} {...props}>
    <ListItemButton>
      <ListItemIcon sx={{ mr: 1 }}>
        <Avatar
          // component="img"
          sx={{
            aspectRatio: '1/1',
            height: 'auto',
            width: '5rem',
            img: { objectFit: 'contain' },
          }}
          src={mealTime.product?.front_image || NO_IMAGE_PATH}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <>
            {mealTime.product?.name}
            <Box component="span" sx={{ display: 'block' }}>
              {mealTime.product && (
                <FullOrFractionValue
                  intVal={mealTime.intCount}
                  fractionCount={mealTime.fractionCount}
                  count_fractions_in_product={
                    mealTime.count_fractions_in_product
                  }
                  count={mealTime.count}
                  quantity={mealTime.product.quantity}
                  unitName={mealTime.product.unit.name}
                />
              )}
            </Box>
          </>
        }
        secondaryTypographyProps={{ component: 'div' }}
        secondary={
          <>
            <Nutrition
              nutrition={mealTime.summaryNutrition}
              color="primary"
              size="small"
              variant="outlined"
            />
          </>
        }
      />
    </ListItemButton>
  </ListItem>
));

interface ContextMenuProps {
  anchorRef: ReturnType<typeof useRef<HTMLElement | undefined>>;
  open: () => void;
  close: () => void;
}

const ContextMenu: React.FC<{
  children: (props: ContextMenuProps) => React.ReactNode;
  content: React.ReactElement;
}> = ({ children, content }) => {
  const anchorRef = useRef<HTMLElement>();
  const [isOpen, open, close] = useBoolean(false);
  return (
    <>
      {children({ open, close, anchorRef })}
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="top"
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => {
                  if (
                    !event.target ||
                    !anchorRef.current?.contains(event.target as HTMLElement)
                  ) {
                    close();
                  }
                }}
              >
                <MenuList onKeyDown={close} onClick={close}>
                  {content}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

interface MealTimeMenuProps {
  onReportBug?: () => void;
  onCopy?: () => void;
}

const MealTimeMenuItems: React.FC<MealTimeMenuProps> = ({
  onReportBug,
  onCopy,
}) => {
  const translate = useTranslate();
  return (
    <>
      <MenuItem onClick={onReportBug}>
        {translate('Сообщить об ошибке')}
      </MenuItem>
      <MenuItem onClick={onCopy}>
        {translate('Скопировать приём пищи')}
      </MenuItem>
    </>
  );
};

const MEAL_TIME_NAME_ORDERING: Record<MealTimeNamesInstance, number> = {
  breakfast: 0,
  dinner: 2,
  lunch: 1,
  snack: 3,
};

export const DiaryMealTimes: React.FC<{
  diary: DiaryInstance;
  onAddMealTime?: (mealTimeName: MealTimeNamesInstance) => void;
  onRemoveMealTime?: (mealTime: MealTimeInstance) => void;
  onChangeMealTime?: (mealTime: MealTimeInstance) => void;
  onClickMealTime?: (mealTime: MealTimeInstance) => void;
  onReportBug?: (mealTime: MealTimeInstance) => void;
  onCopyMealTime?: (mealTime: MealTimeInstance) => void;
  onPasteMealTime?: (
    diary: DiaryInstance,
    mealTiemName: MealTimeNamesInstance,
  ) => void;
  copiedMealTime: MealTimeInstance | null;
}> = ({
  diary,
  onRemoveMealTime,
  onAddMealTime,
  onChangeMealTime,
  onClickMealTime,
  onReportBug,
  onCopyMealTime,
  onPasteMealTime,
  copiedMealTime,
}) => (
  <List>
    {(
      Object.entries(diary.mealTimeGroups) as unknown as [
        MealTimeNamesInstance,
        MealTimeInstance[],
      ][]
    )
      .sort(
        ([mealTimeNameLeft], [mealTimeNameRight]) =>
          MEAL_TIME_NAME_ORDERING[mealTimeNameLeft] -
          MEAL_TIME_NAME_ORDERING[mealTimeNameRight],
      )
      .map(([mealTimeName, mealTimes], index) => (
        <React.Fragment key={index}>
          <MealTimeGroupListItem
            name={mealTimeName}
            nutrition={diary.mealTimesGroupsNutritions[mealTimeName]}
            secondaryAction={
              <>
                {copiedMealTime && (
                  <IconButton
                    edge="end"
                    onClick={() => onPasteMealTime?.(diary, mealTimeName)}
                  >
                    <ContentPasteIcon />
                  </IconButton>
                )}
                <IconButton
                  edge="end"
                  onClick={() => onAddMealTime?.(mealTimeName)}
                >
                  <AddCircleIcon />
                </IconButton>
              </>
            }
          />
          <List dense disablePadding>
            {mealTimes.map((mealTime) => (
              <ContextMenu
                key={mealTime.id}
                content={
                  <MealTimeMenuItems
                    onReportBug={() => onReportBug?.(mealTime)}
                    onCopy={() => onCopyMealTime?.(mealTime)}
                  />
                }
              >
                {({ anchorRef, open }) => (
                  <MealTimeListItem
                    ref={anchorRef as any}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      return onClickMealTime?.(mealTime);
                    }}
                    onContextMenu={(event) => {
                      event.preventDefault();
                      open();
                    }}
                    sx={{
                      py: 0,
                      pl: 0,
                      '.MuiListItemSecondaryAction-root': {
                        display: 'flex',
                        flexDirection: 'column',
                      },
                      '.MuiListItemButton-root': {
                        paddingRight: '0.5rem',
                      },
                    }}
                    mealTime={mealTime}
                    secondaryAction={
                      <>
                        {onRemoveMealTime && mealTime.count <= 1 && (
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              return onRemoveMealTime(mealTime);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                        {onChangeMealTime && mealTime.count > 1 && (
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              return onChangeMealTime({
                                ...getSnapshot(mealTime as any),
                                count: mealTime.count - 1,
                              } as MealTimeInstance);
                            }}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        )}
                        {onChangeMealTime && (
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              return onChangeMealTime({
                                ...getSnapshot(mealTime as any),
                                count: mealTime.count + 1,
                              } as MealTimeInstance);
                            }}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        )}
                      </>
                    }
                  />
                )}
              </ContextMenu>
            ))}
          </List>
          <Divider />
        </React.Fragment>
      ))}
  </List>
);

export const ProductListItem: React.FC<
  {
    product: ProductInstance;
  } & ListItemProps
> = ({ product, ...props }) => {
  const translate = useTranslate();
  return (
    <ListItem {...props}>
      <ListItemButton>
        <ListItemIcon sx={{ mr: 1 }}>
          <Avatar
            sx={{
              aspectRatio: '1/1',
              height: 'auto',
              width: '5rem',
              img: {
                objectFit: 'contain',
              },
            }}
            src={product.front_image || NO_IMAGE_PATH}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Box sx={{ gap: 1, display: 'flex', flexWrap: 'wrap' }}>
              {product.fullName}
              {product?.nutrition && (
                <Nutrition
                  nutrition={product.nutrition}
                  color="primary"
                  size="small"
                  variant="outlined"
                />
              )}
            </Box>
          }
          secondary={
            <Box sx={{ gap: 1, display: 'flex', flexWrap: 'wrap' }}>
              {!product.data_accepted && (
                <Chip
                  component="span"
                  size="small"
                  label={translate('Данные не подтверждены')}
                  variant="outlined"
                  color="error"
                />
              )}
              {!!product.product_info_owner && (
                <Chip
                  component="span"
                  size="small"
                  label={product.product_info_owner}
                  title={translate('product_info_owner', {
                    product_info_owner: product.product_info_owner,
                  })}
                  variant="outlined"
                  color="info"
                />
              )}
            </Box>
          }
          secondaryTypographyProps={{ marginTop: '0.3rem', component: 'div' }}
        />
      </ListItemButton>
    </ListItem>
  );
};
