import {
  LoginRequestSnapshotIn,
  TgLoginRequestRequestSnapshotIn,
  UserInstance,
  TgWebsiteLoginRequestSnapshotIn,
  VKWebsiteLoginRequestSnapshotIn,
} from 'stores/models';
import { buildApiUrl, makeRequest } from '../base';

export const login = (
  credentials: LoginRequestSnapshotIn,
): Promise<UserInstance> =>
  makeRequest(buildApiUrl('/login_user'), {
    method: 'POST',
    body: JSON.stringify(credentials),
  });

export const loginWithExternalService = (
  credentials:
    | TgLoginRequestRequestSnapshotIn
    | TgWebsiteLoginRequestSnapshotIn
    | VKWebsiteLoginRequestSnapshotIn,
): Promise<UserInstance> =>
  makeRequest(buildApiUrl('/login_with_external_service'), {
    method: 'POST',
    body: JSON.stringify(credentials),
  });

export const register = (): Promise<UserInstance> =>
  makeRequest(buildApiUrl('/register'), {
    method: 'POST',
    body: JSON.stringify({}),
  });
