import { types } from 'mobx-state-tree';
import { createModelStore, createQuery } from 'mst-query';
import { loadPrivacyPolicy } from 'stores/api';
import { PrivacyPolicy } from 'stores/models';

const PrivacyPolicyQuery = createQuery('PrivacyPolicyQuery', {
  data: PrivacyPolicy,
  endpoint({ signal }) {
    return loadPrivacyPolicy({ signal });
  },
});

export const PrivacyPolicyStore = createModelStore(
  'PrivacyPolicyStore',
  PrivacyPolicyQuery,
).props({
  privacyPolicyQuery: types.optional(PrivacyPolicyQuery, {}),
});
