import { Button, Container, Drawer, DrawerProps } from '@mui/material';
import { BottomAppBar } from '../shell';
import { memo } from 'react';
import { useTranslate } from 'i18n';

export const FiltersDrawer: React.FC<DrawerProps> = memo(
  ({ children, ...props }) => {
    const translate = useTranslate();
    return (
      <Drawer anchor="right" {...props}>
        <Container sx={{ height: '100vh' }}>{children}</Container>
        <BottomAppBar color="transparent">
          <Button type="submit" fullWidth>
            {translate('Применить')}
          </Button>
        </BottomAppBar>
      </Drawer>
    );
  },
);
