import { destroy, Instance, types, onAction } from 'mobx-state-tree';
import {
  CategoryStore,
  DiaryStore,
  DictionariesStore,
  InstructionStore,
  MealTimeStore,
  NutritionStore,
  PrivacyPolicyStore,
  ProductStore,
  RecipeStore,
  StatisticsStore,
  UnitStore,
  UserSettingsStore,
  UserStore,
} from 'stores/queries';
import { createRootStore, onMutate, QueryClient } from 'mst-query';
import { registerInterceptor } from 'stores/api';

export const RootStore = createRootStore({
  dictionariesStore: types.optional(DictionariesStore, {}),
  categoryStore: types.optional(CategoryStore, {}),
  unitStore: types.optional(UnitStore, {}),
  diaryStore: types.optional(DiaryStore, {}),
  mealTimeStore: types.optional(MealTimeStore, {}),
  productStore: types.optional(ProductStore, {}),
  userStore: types.optional(UserStore, {}),
  // fix fucking circular typing
  userSettingsStore: types.optional(UserSettingsStore, {}) as any,
  recipeStore: types.optional(RecipeStore, {}),
  instructionStore: types.optional(InstructionStore, {}),
  statisticsStore: types.optional(StatisticsStore, {}),
  nutritionsStore: types.optional(NutritionStore, {}),
  privacyPolicyStore: types.optional(PrivacyPolicyStore, {}) as any,
}).actions((self) => ({
  async afterCreate() {
    self.dictionariesStore.dictionariesQuery.refetch();
    self.userSettingsStore.userSettingsQuery.refetch();

    onAction(
      self,
      (action) => {
        if (action.name === 'authorize') {
          self.userSettingsStore.userSettingsQuery.refetch();
        }
        if (action.name === 'logout') {
          self.userSettingsStore.userSettingsQuery.setData(null);
        }
      },
      true,
    );

    onMutate(self.mealTimeStore.removeMealTimeMutation, (data) => {
      if (data) {
        self.diaryStore.diaryQuery.data?.mealtimes.remove(data);
        destroy(data);
      }
    });
  },
}));

export type RootStoreInstance = Instance<typeof RootStore>;
export const queryClient = new QueryClient({ RootStore });
registerInterceptor((error) => {
  if (error?.status === 401) {
    queryClient.rootStore.userStore.logout();
  }
});
