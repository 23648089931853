import { UserSettingsInstance, UserSettingsSnapshotIn } from 'stores/models';
import { buildApiUrl, makeRequest } from '../base';

export const updateUserSettings = (
  userSettings: UserSettingsSnapshotIn,
): Promise<UserSettingsInstance> =>
  makeRequest(buildApiUrl('/user_settings'), {
    method: 'PUT',
    body: JSON.stringify(userSettings),
  });

export const loadUserSettings = (
  options?: any,
): Promise<UserSettingsInstance> =>
  makeRequest(buildApiUrl('/user_settings'), options);
