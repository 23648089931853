import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { Unit } from './Unit';

export const Dictionaries = types
  .model('Dictionaries', {
    units: types.array(types.reference(Unit)),
    default_unit: types.maybeNull(types.reference(Unit)),
    // TODO: Заполненные дни для пользователя. Необходимо вынести для получения списка по месяцам.
    //   filledUTCDates: types.array(types.string),
  })
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    units: snapshot?.units?.slice(),
  }));

export type DictionariesInstance = Instance<typeof Dictionaries>;
export type DictionariesSnapshotIn = SnapshotIn<typeof Dictionaries>;
export type DictionariesSnapshotOut = SnapshotOut<typeof Dictionaries>;
