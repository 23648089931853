import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { withSetPropAction } from 'stores/utils';

export const TelegramUser = types.model({
  id: types.maybe(types.union(types.number, types.string)),
  tg_id: types.maybe(types.maybeNull(types.number)),
  first_name: types.optional(types.string, ''),
  last_name: types.optional(types.string, ''),
  username: types.optional(types.string, ''),
  photo_url: types.maybe(types.string),
  auth_date: types.maybe(types.number),
  hash: types.maybe(types.string),
});

export type TelegramUserInstance = Instance<typeof TelegramUser>;
export type TelegramUserSnapshotIn = SnapshotIn<typeof TelegramUser>;
export type TelegramUserSnapshotOut = SnapshotOut<typeof TelegramUser>;

export const VKUser = types.model({
  vk_id: types.maybe(types.number),
  username: types.string,
  first_name: types.string,
  last_name: types.string,
  avatar: types.string,
});

export type VKUserInstance = Instance<typeof VKUser>;
export type VKUserSnapshotIn = SnapshotIn<typeof VKUser>;
export type VKUserSnapshotOut = SnapshotOut<typeof VKUser>;

export const VKUserRegistration = types.model('VKUserRegistration', {
  refresh_token: types.string,
  access_token: types.string,
  id_token: types.string,
  token_type: types.string,
  expires_in: types.number,
  user_id: types.number,
  state: types.string,
  scope: types.string,
});

export type VKUserRegistrationInstance = Instance<typeof VKUserRegistration>;
export type VKUserRegistrationSnapshotIn = SnapshotIn<
  typeof VKUserRegistration
>;
export type VKUserRegistrationSnapshotOut = SnapshotOut<
  typeof VKUserRegistration
>;

export const User = types
  .model('User', {
    user_id: types.string,
    auth_token: types.string,
    first_name: types.optional(types.string, ''),
    last_name: types.optional(types.string, ''),
    password: types.optional(types.string, ''),
    username: types.optional(types.string, ''),
    created_password: types.optional(types.string, ''),
    tg_user: types.maybe(types.maybeNull(TelegramUser)),
    vk_user: types.maybe(types.maybeNull(VKUser)),
    authenticationMethod: types.optional(
      types.enumeration(['tg_mini_app', 'tg_website', 'vk_website', 'unknown']),
      'unknown',
    ),
  })
  .actions(withSetPropAction);

export type UserInstance = Instance<typeof User>;
export type UserSnapshotIn = SnapshotIn<typeof User>;
export type UserSnapshotOut = SnapshotOut<typeof User>;

export const LoginRequest = types.model('LoginRequest', {
  password: types.string,
  username: types.string,
});

export type LoginRequestInstance = Instance<typeof LoginRequest>;
export type LoginRequestSnapshotIn = SnapshotIn<typeof LoginRequest>;
export type LoginRequestSnapshotOut = SnapshotOut<typeof LoginRequest>;

export const TgLoginRequest = types.model('TgLoginRequest', {
  tg_init_data_raw: types.string,
});

export type TgLoginRequestRequestInstance = Instance<typeof TgLoginRequest>;
export type TgLoginRequestRequestSnapshotIn = SnapshotIn<typeof TgLoginRequest>;
export type TgLoginRequestRequestSnapshotOut = SnapshotOut<
  typeof TgLoginRequest
>;

export const TgWebsiteLoginRequest = types.model('TgWebsiteLoginRequest', {
  tg_website_user: TelegramUser,
});

export type TgWebsiteLoginRequestInstance = Instance<
  typeof TgWebsiteLoginRequest
>;
export type TgWebsiteLoginRequestSnapshotIn = SnapshotIn<
  typeof TgWebsiteLoginRequest
>;
export type TgWebsiteLoginRequestSnapshotOut = SnapshotOut<
  typeof TgWebsiteLoginRequest
>;

export const VKWebsiteLoginRequest = types.model('VKWebsiteLoginRequest', {
  vk_website_user: VKUserRegistration,
});

export type VKWebsiteLoginRequestInstance = Instance<
  typeof VKWebsiteLoginRequest
>;
export type VKWebsiteLoginRequestSnapshotIn = SnapshotIn<
  typeof VKWebsiteLoginRequest
>;
export type VKWebsiteLoginRequestSnapshotOut = SnapshotOut<
  typeof VKWebsiteLoginRequest
>;
