import { types, getSnapshot } from 'mobx-state-tree';
import { createModelStore, createMutation, createQuery } from 'mst-query';
import { saveMealTime, loadMealTime, removeMealTime } from 'stores/api';
import { getUTCDate } from 'stores';
import {
  DiaryInstance,
  guardMealTimeName,
  MealTime,
  MealTimeInstance,
  MealTimeNames,
  MealTimeNamesInstance,
  SaveMealTime,
  SaveMealTimeSnapshotIn,
} from 'stores/models';

export const MealTimeQuery = createQuery('MealTimeQuery', {
  data: types.reference(MealTime),
  request: types.model({ id: types.string, name: types.maybe(MealTimeNames) }),
  endpoint({ request, signal }) {
    if (request.id === 'new') {
      return Promise.resolve({
        id: request.id,
        name: guardMealTimeName(request.name),
        count: 1,
      });
    }
    return loadMealTime(request.id, { signal });
  },
});

export const SaveMealTimeMutation = createMutation('SaveMealTimeMutation', {
  data: types.safeReference(MealTime),
  request: SaveMealTime,
  endpoint({ request }) {
    return saveMealTime(getSnapshot(request));
  },
});

export const RemoveMealTimeMutation = createMutation('RemoveMealTimeMutation', {
  data: types.safeReference(MealTime),
  request: types.string,
  endpoint({ request }) {
    return removeMealTime(request);
  },
});

export const MealTimeStore = createModelStore('MealTimeStore', MealTime)
  .props({
    saveMealTimeMutation: types.optional(SaveMealTimeMutation, {}),
    removeMealTimeMutation: types.optional(RemoveMealTimeMutation, {}),
    mealTimeQuery: types.optional(MealTimeQuery, {}),
    copiedMealTime: types.optional(
      types.maybeNull(types.reference(MealTime)),
      null,
    ),
  })
  .actions((self) => ({
    copyMealTime: (mealTime: MealTimeInstance | null) =>
      (self.copiedMealTime = mealTime),
  }))
  .actions((self) => ({
    pasteMealTime: async (
      diary: DiaryInstance | string,
      mealTimeName: MealTimeNamesInstance,
    ) => {
      const mutation = await self.saveMealTimeMutation.mutate({
        request: {
          ...self.copiedMealTime!,
          id: 'new',
          name: mealTimeName,
          diary_date:
            typeof diary === 'string' ? diary : getUTCDate(diary.date!),
          product_id: self.copiedMealTime!.product!.id,
        } as SaveMealTimeSnapshotIn,
      });
      const { data, error } = mutation;
      if (!error) {
        if (typeof diary !== 'string') {
          diary.addMealTime(data);
        }
        self.copyMealTime(null);
      }
      return mutation;
    },
  }));
