import { useFormikContext } from 'formik';
import {
  calculateCalories,
  concatFormikNames,
  NutritionInstance,
} from 'stores';
import * as yup from 'yup';
import { MuiFormikField } from './MuiFormikField';
import { Box, TextField } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { formatNutritionValue } from 'utils';
import { useTranslate } from 'i18n';
import { useMemo } from 'react';

export function useNutritionSchema() {
  const translate = useTranslate();
  return useMemo(
    () =>
      yup.object({
        protein: yup
          .number()
          .min(
            0,
            translate('Количество белков должно быть больше или равно нулю'),
          )
          .required(translate('Необходимо заполнить количество белков')),
        fat: yup
          .number()
          .min(
            0,
            translate('Количество жиров должно быть больше или равно нулю'),
          )
          .required(translate('Необходимо заполнить количество жиров')),
        carbohydrates: yup
          .number()
          .min(
            0,
            translate('Количество углеводов должно быть больше или равно нулю'),
          )
          .required(translate('Необходимо заполнить количество углеводов')),
      }),
    [translate],
  );
}

const CaloriesField: React.FC<{ name?: string }> = ({ name }) => {
  const translate = useTranslate();
  const { values } = useFormikContext<NutritionInstance | any>();
  const calories = calculateCalories(name ? (values as any)[name] : values);
  return (
    <MuiFormikField name={concatFormikNames(name, 'calories')}>
      {(props: any) => (
        <TextField
          type="number"
          label={translate('Калории')}
          disabled
          {...props}
          value={formatNutritionValue(calories)}
        />
      )}
    </MuiFormikField>
  );
};

export const NutritionFields: React.FC<{
  name?: string;
  required?: boolean;
}> = ({ name, required }) => {
  const translate = useTranslate();
  return (
    <>
      <CaloriesField name={name} />
      <MuiFormikField name={concatFormikNames(name, 'protein')}>
        {(props: any) => (
          <TextField
            type="number"
            label={translate('Белки')}
            required={required}
            {...props}
          />
        )}
      </MuiFormikField>
      <MuiFormikField name={concatFormikNames(name, 'fat')}>
        {(props: any) => (
          <TextField
            type="number"
            label={translate('Жиры')}
            required={required}
            {...props}
          />
        )}
      </MuiFormikField>
      <MuiFormikField name={concatFormikNames(name, 'carbohydrates')}>
        {(props: any) => (
          <TextField
            type="number"
            label={translate('Углеводы')}
            required={required}
            {...props}
          />
        )}
      </MuiFormikField>
    </>
  );
};

export const NutritionFieldsContainer: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(2,auto)',
      gap: 1,
    }}
  >
    {children}
  </Box>
);
