import {
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';
import React, { useMemo } from 'react';
import { MuiFormikField } from './MuiFormikField';
import {
  NutritionFields,
  NutritionFieldsContainer,
  useNutritionSchema,
} from './NutritionFields';
import { BarcodeReaderButton } from '../dialogs/BarcodeDialog';
import { ImageField } from './Fields';
import { CategoryField, UnitField } from '../dictionaries';
import { isValidEAN } from 'utils';
import { useTranslate } from 'i18n';

export function useProductFormSchema() {
  const translate = useTranslate();
  const nutritionSchema = useNutritionSchema();
  return useMemo(
    () =>
      yup.object({
        id: yup.string().optional(),
        name: yup
          .string()
          .min(1)
          .required(translate('Необходимо ввести наименование')),
        category: yup
          .array()
          .min(1, translate('Необходимо выбрать категорию'))
          .required(translate('Необходимо выбрать категорию')),
        nutrition: nutritionSchema,
        unit: yup
          .array()
          .min(1, translate('Необходимо выбрать единицу измерения'))
          .required(translate('Необходимо выбрать единицу измерения')),
        brand: yup.string().optional(),
        barcode: yup
          .string()
          .optional()
          .test('isValidEAN', translate('Штрих-код некорректен'), (value) =>
            value ? isValidEAN(value) : true,
          ),
        quantity: yup
          .number()
          .required(
            translate(
              'Необходимо ввести вес продукта в указанной единице измерения',
            ),
          ),
        photo_back: yup
          .string()
          .required(translate('Необходимо фото оборотной стороны продукта')),
        photo_front: yup
          .string()
          .required(translate('Необходимо фото передней стороны продукта')),
      }),
    [nutritionSchema, translate],
  );
}

export type ProductFormSchemaType = yup.InferType<
  ReturnType<typeof useProductFormSchema>
>;

export function useRecipeFormSchema() {
  const productFormSchema = useProductFormSchema();
  const translate = useTranslate();
  return useMemo(
    () =>
      productFormSchema.concat(
        yup.object({
          ingredients: yup
            .array(productFormSchema)
            .min(1, translate('Выберите хотя бы один игнредиент')),
          cooking_time_seconds: yup.number().min(0).default(0),
          instructions: yup.array(productFormSchema).min(0),
        }),
      ),
    [productFormSchema, translate],
  );
}

const BarcodeField: React.FC<TextFieldProps> = (props) => {
  const translate = useTranslate();
  return (
    <TextField
      label={translate('Штрих-код')}
      placeholder={translate('Введите штрих-код продукта')}
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <BarcodeReaderButton
              onCapture={(barcode) =>
                props.onChange?.({
                  target: { name: props.name, value: barcode },
                } as any)
              }
            />
          </InputAdornment>
        ),
        ...props.InputProps,
      }}
    />
  );
};

const fieldProps = {
  variant: 'outlined',
  fullWidth: true,
};

export const ProductFields: React.FC = observer(() => {
  const translate = useTranslate();
  return (
    <>
      <MuiFormikField name="barcode">
        {(props: any) => (
          <BarcodeField {...props} {...fieldProps} type="number" autoFocus />
        )}
      </MuiFormikField>
      <MuiFormikField type="image" name="photo_front">
        {(props: any) => (
          <ImageField
            {...props}
            label={translate('Фото продукта с лицевой стороны')}
            labelPlacement="top"
            required
          />
        )}
      </MuiFormikField>
      <MuiFormikField type="image" name="photo_back">
        {(props: any) => (
          <ImageField
            {...props}
            label={translate(
              'Фото продукта с оборотной стороны с указанием БЖУ',
            )}
            labelPlacement="top"
            required
          />
        )}
      </MuiFormikField>
      <MuiFormikField name="name">
        {(props: any) => (
          <TextField
            {...props}
            {...fieldProps}
            type="text"
            label={translate('Название')}
            required
          />
        )}
      </MuiFormikField>
      <MuiFormikField name="category" type="dict">
        {(props: any) => (
          <CategoryField
            fieldProps={props.props}
            {...props}
            {...fieldProps}
            required
          />
        )}
      </MuiFormikField>
      <MuiFormikField name="unit" type="dict">
        {(props: any) => (
          <UnitField
            fieldProps={props.props}
            {...props}
            {...fieldProps}
            required
          />
        )}
      </MuiFormikField>
      <MuiFormikField name="quantity">
        {(props: any) => (
          <TextField
            label={translate('Вес продукта')}
            placeholder={translate('Введите вес продукта')}
            type="number"
            required
            {...props}
            {...fieldProps}
          />
        )}
      </MuiFormikField>
      <MuiFormikField name="brand">
        {(props: any) => (
          <TextField
            label={translate('Название бренда')}
            placeholder={translate('Название бренда')}
            {...props}
            {...fieldProps}
          />
        )}
      </MuiFormikField>
      <Card>
        <CardHeader title={translate('Состав БЖУ на 100 грамм')} />
        <CardContent>
          <NutritionFieldsContainer>
            <NutritionFields name="nutrition" required />
          </NutritionFieldsContainer>
        </CardContent>
      </Card>
    </>
  );
});

export const RecipeFields: React.FC = observer(() => (
  <>
    <ProductFields />
  </>
));
