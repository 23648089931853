import { path } from 'static-path';

const root = path('/');

const recipies = root.path('/recipies');
const recipe = recipies.path('/:recipe_id');
const recipeNew = recipies.path('/new');
const recipeReportBug = recipe.path('/report_bug');

const diary = root.path('/diary');
const diaryDetail = diary.path('/:diary_date');

const mealTime = diaryDetail.path('/meal_time/:meal_time_name');
const mealTimeEdit = mealTime.path('/:meal_time_id');

const profile = root.path('/profile');

const statistics = root.path('/statistics');

const auth = profile.path('/auth');

const privacy = root.path('/privacy');

const paths = {
  root,
  recipies,
  recipe,
  recipeNew,
  diary,
  mealTimeEdit,
  profile,
  statistics,
  diaryDetail,
  auth,
  recipeReportBug,
  privacy,
};

Object.freeze(paths);

export default paths;
