import { getSnapshot, types } from 'mobx-state-tree';
import {
  createModelStore,
  createMutation,
  createInfiniteQuery,
} from 'mst-query';
import {
  createPaginated,
  createPaginatedElement,
  PaginationFilters,
} from 'stores/queries';
import {
  loadProducts,
  loadProductsByBarcode,
  reportProductBug,
  saveProduct,
} from 'stores/api';
import {
  Product,
  CategoriesFilters,
  ProductSnapshotOut,
  ReportProductBug,
} from 'stores/models';
import { isBarCodeText, removeSpaces } from 'utils';

export const ProductsQuery = createInfiniteQuery('ProductsQuery', {
  data: createPaginated(types.reference(Product)),
  request: types.compose(CategoriesFilters, PaginationFilters),
  onQueryMore({ data, query }) {
    query.data?.results.push(...data.results);
    if (query.data) {
      query.data.next = data.next;
      // query.data.previous = data.previous;
    }
  },
  endpoint({ signal, request, pagination }) {
    if (isBarCodeText(request.search)) {
      return createPaginatedElement(
        loadProductsByBarcode(removeSpaces(request.search)!),
      );
    }
    return loadProducts({ ...request, ...pagination }, { signal });
  },
});

export const CreateProductMutation = createMutation('CreateProductMutation', {
  data: types.safeReference(Product),
  request: types.union(types.reference(Product), Product),
  endpoint({ request }) {
    return saveProduct(getSnapshot(request) as ProductSnapshotOut);
  },
});

export const ReportProductBugMutation = createMutation(
  'ReportProductBugMutation',
  {
    request: ReportProductBug,
    endpoint({ request }) {
      return reportProductBug(getSnapshot(request));
    },
  },
);

export const ProductStore = createModelStore('ProductStore', Product).props({
  productsQuery: types.optional(ProductsQuery, {}),
  createProductMutation: types.optional(CreateProductMutation, {}),
  reportProductBugMutation: types.optional(ReportProductBugMutation, {}),
});
