import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import 'vite/modulepreload-polyfill';
import { retrieveLaunchParams, swipeBehavior, init } from '@telegram-apps/sdk';
import { setTelegramMiniAppUser } from 'stores/api/index';
import { initSentry } from './integrations';

initSentry();

if ((window as any).Telegram.WebApp.initData) {
  try {
    const launchParams = retrieveLaunchParams();
    setTelegramMiniAppUser(launchParams);
  } catch {
    // nothing
  }

  try {
    init();
    if (swipeBehavior.mount.isAvailable()) {
      swipeBehavior.mount();
      swipeBehavior.isMounted();
      swipeBehavior.disableVertical();
      swipeBehavior.isVerticalEnabled();
    }
  } catch (err) {
    console.error(err);
  }
}

// Для фиксов отображения с клавиатурой
if ('virtualKeyboard' in navigator && navigator.virtualKeyboard) {
  (navigator.virtualKeyboard as any).overlaysContent = true;
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
