import { createInfiniteQuery, createModelStore } from 'mst-query';
import { CategoriesFilters, Category } from 'stores/models';
import { createPaginated, PaginationFilters } from './utils';
import { types } from 'mobx-state-tree';
import { loadCategories } from 'stores/api';

const CategoriesQuery = createInfiniteQuery('CategoriesQuery', {
  data: createPaginated(types.reference(Category)),
  request: types.compose(CategoriesFilters, PaginationFilters),
  onQueryMore({ data, query }) {
    query.data?.results.push(...data.results);
    if (query.data) {
      query.data.next = data.next;
      // query.data.previous = data.previous;
    }
  },
  endpoint({ signal, request, pagination }) {
    return loadCategories({ ...request, ...pagination }, { signal });
  },
});

export const CategoryStore = createModelStore('CategoryStore', Category).props({
  categoriesQuery: types.optional(CategoriesQuery, {}),
});
