import {
  Box,
  BoxProps,
  Chip,
  ChipProps,
  CircularProgress,
  CircularProgressProps,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from '@mui/material';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import { formatNutritionValue, normalize } from 'utils';
import React from 'react';
import lodash from 'lodash';
import { NutritionInstance } from 'stores';
import styled from '@emotion/styled';
import { useTranslate } from 'i18n';

const NoWrapSpanBox = styled((props: BoxProps) => (
  <Box {...props} component="span" />
))({
  whiteSpace: 'nowrap',
});

export const Nutrition: React.FC<
  { nutrition: NutritionInstance } & ChipProps
> = ({ nutrition, ...props }) => {
  const translate = useTranslate();
  return (
    <Chip
      sx={{
        height: 'auto',
        '.MuiChip-label ': {
          whiteSpace: 'pre-line',
          textOverflow: 'inherit',
          wordWrap: 'break-word',
        },
      }}
      icon={<RestaurantMenuIcon />}
      label={
        <>
          <NoWrapSpanBox>{translate('Б/Ж/У:')}</NoWrapSpanBox>
          <NoWrapSpanBox>
            {formatNutritionValue(nutrition.protein)}/
            {formatNutritionValue(nutrition.fat)}/
            {formatNutritionValue(nutrition.carbohydrates)}
          </NoWrapSpanBox>{' '}
          <NoWrapSpanBox>
            {formatNutritionValue(nutrition.calories)} {translate('Ккал')}
          </NoWrapSpanBox>
        </>
      }
      {...props}
    />
  );
};

type ProgressTypes = 'linear' | 'circular';

type SelectProgresByType<T extends ProgressTypes> = T extends 'linear'
  ? LinearProgressProps
  : CircularProgressProps;

type NutritionProgressProps<T extends ProgressTypes> = {
  label?: React.ReactNode;
  value: number;
  target: number;
  type?: T;
  children?: React.ReactNode;
} & Omit<SelectProgresByType<T>, 'value'>;

export function NutritionProgress<T extends ProgressTypes>({
  target,
  value,
  type,
  label,
  children,
  ...props
}: NutritionProgressProps<T>) {
  const progress = lodash.clamp(normalize(value, 0, target), 0, 100);
  if (type === 'circular') {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          sx={(theme) => ({
            position: 'absolute',
            left: 0,
            color: theme.palette.grey[200],
            ...theme.applyStyles('dark', {
              color: theme.palette.grey[800],
            }),
          })}
          {...props}
          value={100}
        />
        <CircularProgress
          color="primary"
          variant="determinate"
          {...(props as CircularProgressProps)}
          value={progress}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: 'text.secondary' }}
          >
            {children
              ? children
              : `${formatNutritionValue(value)}/${formatNutritionValue(target)}`}
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="caption"
        component="div"
        sx={{ color: 'text.secondary', textAlign: 'center' }}
      >
        {label}
      </Typography>
      <LinearProgress
        color="primary"
        variant="determinate"
        {...(props as LinearProgressProps)}
        value={progress}
      />
      <Typography
        variant="caption"
        component="div"
        sx={{ color: 'text.secondary', textAlign: 'center' }}
      >
        {formatNutritionValue(value)}/{formatNutritionValue(target)}
      </Typography>
    </Box>
  );
}
