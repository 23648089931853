import { TextField } from '@mui/material';
import { useTranslate } from 'i18n';
import { observer } from 'mobx-react-lite';
import { MuiFormikField } from 'src/components';

export const RecipiesFilterFields = observer(() => {
  const translate = useTranslate();
  return (
    <>
      <MuiFormikField name="name">
        {(props: any) => (
          <TextField
            {...props}
            type="text"
            label={translate('Наименование')}
            variant="standard"
            fullWidth
          />
        )}
      </MuiFormikField>
    </>
  );
});
