import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import paths from 'src/paths';
import { getUTCDate } from 'stores';
import { getUser } from 'stores/api';

const RootProtector = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!getUser()?.auth_token) {
      if (location.pathname === paths.privacy({})) {
        return;
      }
      navigate(paths.auth({}), { viewTransition: true });
      return;
    }
    if (location.pathname === paths.root({})) {
      navigate(paths.diaryDetail({ diary_date: getUTCDate(new Date()) }), {
        viewTransition: true,
      });
      return;
    }
  }, [location.pathname, navigate]);

  return null;
});

RootProtector.displayName = 'RootProtector';

export default RootProtector;
