import {
  Button,
  ButtonProps,
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { PhotoWebcam, PhotoWebcamProps } from './Camera';
import { useBoolean } from 'hooks';
import { PhotoCamera } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback } from 'react';
import { useTranslate } from 'i18n';

export const PhotoDialog: React.FC<
  Omit<DialogProps, 'onClose'> &
    Pick<PhotoWebcamProps, 'onCapture'> & { onClose: () => void }
> = ({ onCapture, ...props }) => {
  const translate = useTranslate();
  return (
    <Dialog {...props}>
      <DialogTitle sx={{ mr: 3 }}>{translate('Фото')}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <PhotoWebcam onCapture={onCapture} />
    </Dialog>
  );
};

export const PhotoDialogButton: React.FC<
  ButtonProps & Pick<PhotoWebcamProps, 'onCapture'>
> = ({ onCapture, ...props }) => {
  const translate = useTranslate();
  const [isOpen, open, close] = useBoolean();
  const handleCapture = useCallback(
    (image: string) => {
      close();
      return onCapture(image);
    },
    [close, onCapture],
  );
  if (!navigator.mediaDevices) {
    console.error('navigator.mediaDevices is not available');
    return null;
  }
  return (
    <>
      <Button startIcon={<PhotoCamera />} {...props} onClick={open}>
        {props.children || translate('Сделать фото')}
      </Button>
      <PhotoDialog
        open={isOpen}
        onCapture={handleCapture}
        onClose={close}
        fullScreen
      />
    </>
  );
};
