import {
  Box,
  Button,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  TextFieldProps,
  styled,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImageIcon from '@mui/icons-material/Image';
import { PhotoDialogButton } from 'src/components';
import { useTranslate } from 'i18n';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export interface ImageFieldProps
  extends Omit<FormControlLabelProps, 'control'>,
    Pick<TextFieldProps, 'error' | 'helperText'> {
  src: string;
  direction?: 'column' | 'horizontal';
  onCapture?: (image: string) => void;
}

export const ImageField: React.FC<ImageFieldProps> = ({
  src,
  error,
  helperText,
  direction = 'column',
  onCapture,
  ...props
}) => {
  const translate = useTranslate();
  return (
    <Box
      sx={{
        width: 'fit-content',
        display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        flexDirection: 'column',
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: direction,
        }}
      >
        <FormControlLabel
          labelPlacement="top"
          {...props}
          sx={{ alignItems: 'flex-start' }}
          // control={<input name={props.name} type="file" accept="image/*" />}
          control={
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                {translate('Загрузить файл')}
                <VisuallyHiddenInput
                  name={props.name}
                  type="file"
                  accept="image/*"
                />
              </Button>
              {onCapture && (
                <PhotoDialogButton
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  onCapture={onCapture}
                />
              )}
            </Box>
          }
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 1,
            marginLeft: 1,
          }}
        >
          {src ? (
            <Box component="img" src={src} sx={{ width: '100%' }} />
          ) : (
            <ImageIcon />
          )}
        </Box>
      </Box>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Box>
  );
};
