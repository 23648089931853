import { useTranslateSettings } from 'i18n';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { getUserLanguageCode, useRootStore } from 'stores';
import { ru, enUS } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';

/**
 * Контролирует изменение языка в приложении
 */
const LanguageDetector = observer(() => {
  const { changeLanguage } = useTranslateSettings();
  const {
    userSettingsStore: { userSettingsQuery },
  } = useRootStore();
  const languageCode = userSettingsQuery.data?.language_code;

  useEffect(() => {
    changeLanguage(getUserLanguageCode(languageCode));
  }, [changeLanguage, languageCode]);

  useEffect(() => {
    setDefaultOptions({ locale: languageCode?.includes('en') ? enUS : ru });
  }, [languageCode]);

  return null;
});

export default LanguageDetector;
