import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_translations_json from './translations/en.json';
import ru_translations_json from './translations/ru.json';
import { i18nextPlugin } from 'translation-check';
import { DEFAULT_LANGUAGE_CODE } from 'food_constants';
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: en_translations_json as Resource,
  ru: ru_translations_json as Resource,
};

i18n
  .use(i18nextPlugin)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: DEFAULT_LANGUAGE_CODE, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
