import {
  ALLOWED_LANGUAGE_CODES,
  AllowedLanguageCodes,
  DEFAULT_LANGUAGE_CODE,
  TELEGRAM_MINI_APP_DEFAULT_LANGUAGE_CODE,
} from 'food_constants';
import { getSnapshot, types } from 'mobx-state-tree';
import { createModelStore, createMutation, createQuery } from 'mst-query';
import {
  getTelegramMiniAppUser,
  loadUserSettings,
  updateUserSettings,
} from 'stores/api';
import { UserSettings, UserSettingsSnapshotIn } from 'stores/models';
import { withSetPropAction } from 'stores/utils';

const UpdateUserSettings = createMutation('UpdateUserSettings', {
  data: types.reference(UserSettings),
  request: types.union(types.reference(UserSettings), UserSettings),
  endpoint({ request }) {
    return updateUserSettings(getSnapshot(request) as UserSettingsSnapshotIn);
  },
});

export const getUserLanguageCode = (
  languageCode?: AllowedLanguageCodes,
): AllowedLanguageCodes => {
  if (languageCode) return languageCode;
  const tgMiniAppUser = getTelegramMiniAppUser();
  if (tgMiniAppUser) {
    if (
      tgMiniAppUser?.languageCode &&
      ALLOWED_LANGUAGE_CODES.some((allowedCode) =>
        tgMiniAppUser.languageCode!.includes(allowedCode),
      )
    ) {
      // Если ТГ прислал нам код языка и он в списке доступных, то устанавливаем его
      return tgMiniAppUser.languageCode as AllowedLanguageCodes;
    } else {
      // Если ТГ не прислал нам код языка или он не в списке доступных, то устанавливаем код языка по умолчанию для ТГ мини приложений
      return TELEGRAM_MINI_APP_DEFAULT_LANGUAGE_CODE;
    }
  }
  return DEFAULT_LANGUAGE_CODE;
};

const UserSettingsQuery = createQuery('UserSettingsQuery', {
  data: types.reference(UserSettings),
  async endpoint({ signal }) {
    const response = await loadUserSettings({ signal });
    response.language_code = getUserLanguageCode(response.language_code as any);
    return response;
  },
});

export const UserSettingsStore = createModelStore(
  'UserSettingsStore',
  UserSettings,
)
  .props({
    userSettingsMutation: types.optional(UpdateUserSettings, {}),
    userSettingsQuery: types.optional(UserSettingsQuery, {}),
  })
  .actions(withSetPropAction);
