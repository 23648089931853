import { Button, ButtonTypeMap, ExtendButtonBase, styled } from '@mui/material';
import { BottomAppBar } from 'src/components/shell';
import BookIcon from '@mui/icons-material/Book';
// import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import PersonIcon from '@mui/icons-material/Person';
import paths from 'src/paths';
import { Link } from 'react-router-dom';
import { useTranslate } from 'i18n';

const FooterButton = styled(Button)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}) as ExtendButtonBase<ButtonTypeMap>;

export const Footer = () => {
  const translate = useTranslate();
  return (
    <BottomAppBar sx={{ bgcolor: 'background.paper', height: 64 }}>
      <FooterButton variant="text" component={Link} to={paths.diary({})} viewTransition>
        <BookIcon />
        {translate('Дневник')}
      </FooterButton>
      {/* <FooterButton variant="text" component={Link} to={paths.recipies({})} viewTransition>
              <AutoStoriesIcon />
              {translate('Рецепты')}
            </FooterButton> */}
      <FooterButton variant="text" component={Link} to={paths.profile({})} viewTransition>
        <PersonIcon />
        {translate('Профиль')}
      </FooterButton>
    </BottomAppBar>
  );
};
